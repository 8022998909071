import { Component, Input, NgZone } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-link',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './link.component.html',
  styleUrl: './link.component.css'
})
export class LinkComponent {
  @Input() label = '';
  @Input() link = '';

  constructor(
    private router: Router,
    private zone: NgZone,
  ){}

  public gotoLink() {
    this.zone.run(() => {
      this.router.navigate([this.link]);
    });
  }
}
